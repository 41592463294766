<template>
  <div>

    <nav>
      <h2 class='company-sidebar__title'>Core</h2>
      <a class='company-sidebar__a' v-tooltip.right='core.name' :href="core.url" v-for='(core, index) in cores' :class='core.selected'>
        <div class="asset-icon">
          <i :class="core.i"></i>
        </div>
        <span class="company-sidebar__name">{{core.name}}</span>
        <span class="count" v-if='core.count !== false'>{{core.count}}</span>
      </a>
    </nav>

    <nav>
      <h2 class='company-sidebar__title'>Tools</h2>
      <a class='company-sidebar__a' v-tooltip.right='tool.name' :href="tool.url" v-for='(tool, index) in tools' :class='tool.selected'>
        <div class="asset-icon">
          <i :class="tool.i"></i>
        </div>
        <span class="company-sidebar__name">{{tool.name}}</span>
        <span class="count" v-if='tool.count !== false'>{{tool.count}}</span>
      </a>
    </nav>

    <div v-if='data.length > 0'>
      <h2 class='company-sidebar__title'>Custom</h2>
      <div href='#' v-for='folder in folders' class='company-sidebar__folder'>
        <a href='#' class='company-sidebar__folder__a' @click.prevent.stop='toggleFolder(folder.id)' v-tooltip.right='folder.name'>
          <div class='asset-icon'>
            <i class='fal fa-folder' v-if='isFolderHidden(folder.id)'></i>
            <i class='fal fa-folder-open' v-if='!isFolderHidden(folder.id)'></i>
          </div>
          <span class="company-sidebar__name">{{folder.name }}</span>
        </a>
        <a :href="d.url" v-tooltip.right='`${d.name} in ${folder.name}`' v-if='showSidebarChild(folder.id, d.sidebar_folder_id)' v-for='(d, index) in filteredData' :class='{"selected": d.selected, "company-sidebar__folder-item": true, "company-sidebar__a": true}'>
          <div class="asset-icon" :style="{background: d.color}">
            <i :class="d.icon" :style="{color: d.icon_color}"></i>
          </div>
          <span class="company-sidebar__name">{{d.name}}</span>
          <span class="count">{{d.count}}</span>
        </a>
      </div>
      
      <a class='company-sidebar__a' v-tooltip.right='d.name' :href="d.url" v-if='!d.sidebar_folder_id' v-for='(d, index) in filteredData' :class='{"selected": d.selected}'>
        <div class="asset-icon" :style="{background: d.color}">
          <i :class="d.icon" :style="{color: d.icon_color}"></i>
        </div>
        <span class="company-sidebar__name">{{d.name}}</span>
        <span class="count">{{d.count}}</span>
      </a>

      <div class='company-sidebar__buttons'>
        <a href='#' v-if='sidebarLevel == 0' @click.prevent.stop='resizeSidebar(1)'><i class="fas fa-angle-double-left"></i><span>Collapse</span></a>
        <a href='#' v-if='sidebarLevel == 1' @click.prevent.stop='resizeSidebar(2)'><i class="fas fa-angle-double-right"></i><span>Expand</span></a>
        <a href='#' v-if='sidebarLevel == 2' @click.prevent.stop='resizeSidebar(0)'><i class="fas fa-angle-double-left"></i><span>Collapse</span></a>
        <a href='#' v-if='!hideEmpty' title='Hide Blank Custom Assets' @click.prevent.stop='toggleEmpty'><i class='fas fa-eye-slash'></i><span>Hide Blank</span></a>
        <a href='#' v-if='hideEmpty' title='Show Blank Custom Assets' @click.prevent.stop='toggleEmpty'><i class='fas fa-eye'></i><span>Show Blank</span></a>
      </div>
    </div>
  </div>
</template>

<script>
import FloatingVue from 'floating-vue';
export default {
  props: ["cores", "tools", "data", "folders"],
  data () {
    return {
      sidebarLevel: 0,
      hideEmpty: false,
      hides: []
    }        
  },
  mounted () {

    
    FloatingVue.options.themes.tooltip.disabled = true;

    if (localStorage.getItem('huduHideEmptySidebarAssets')) {
      try {
        this.hideEmpty = true;
      } catch(e) {
        
      }
    }
    if (localStorage.getItem('huduSidebarFolderHides')) {
      try {
        this.hides = JSON.parse(localStorage.getItem('huduSidebarFolderHides'));
      } catch(e) {
        
      }
    }
    if (localStorage.getItem('huduSidebarResizeLevel')) {
      try {
        this.sidebarLevel = localStorage.getItem('huduSidebarResizeLevel')
        this.calculateSidebarWidth(this.sidebarLevel)
      } catch(e) {
        
      }
    }
  },
  methods: {
    resizeSidebar (level) {
      this.sidebarLevel = level
      localStorage.setItem('huduSidebarResizeLevel', level);

      this.calculateSidebarWidth(level)
    },
    calculateSidebarWidth(level) {
      var element = document.getElementById('company-sidebar');
      let root = document.documentElement;

      if (level == 1) {
        FloatingVue.options.themes.tooltip.disabled = false;
        element.classList.add("company-sidebar--skinny");
        root.style.setProperty('--sidebarwidth', '66px');
      } else if (level == 2) {
        FloatingVue.options.themes.tooltip.disabled = true;
        element.classList.remove("company-sidebar--skinny");
        root.style.setProperty('--sidebarwidth', '380px');
      } else {
        FloatingVue.options.themes.tooltip.disabled = true;
        element.classList.remove("company-sidebar--skinny");
        root.style.setProperty('--sidebarwidth', '235px');
      }
    },
    showSidebarChild(folder_id, parent_id) {
      if ((!this.isFolderHidden(folder_id)) && (parent_id === folder_id))
        return true
      return false
    },
    isFolderHidden(id) {
      if (this.hides.includes(id))
        return true;
      return false
    },
    toggleFolder(id) {
      if(this.isFolderHidden(id)) {
        const index = this.hides.indexOf(id);
        if (index > -1) {
          this.hides.splice(index, 1);
          localStorage.setItem('huduSidebarFolderHides', JSON.stringify(this.hides));
        }
      } else {
        this.hides.push(id);
        localStorage.setItem('huduSidebarFolderHides', JSON.stringify(this.hides));
      }
    },
    toggleEmpty () {
      this.hideEmpty = !this.hideEmpty;

      if (this.hideEmpty === true) {
        localStorage.hide_assets = true
        localStorage.setItem('huduHideEmptySidebarAssets', true);
      } else {
        localStorage.removeItem('huduHideEmptySidebarAssets')
      }
      
      // if (this.hideEmpty === true) {
        
      //   localStorage.setItem('huduHideEmptySidebarAssets', false);
      // } else {
      //   this.hideEmpty = true;
      //   localStorage.setItem('huduHideEmptySidebarAssets', true);
      // }
    }
  },
  computed: {
    filteredData() {
      return this.hideEmpty ? this.data.filter(item => !(item.count == 0)) : this.data
    },
    filteredFolderData() {
      return this.filteredData.filter(item => item.sidebar_folder_id == this.activeFolderId)
    }
  }
}
</script>